import React, { Component } from "react";

import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import MessageIcon from '@material-ui/icons/Message';
import SendIcon from '@material-ui/icons/Send';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

/*
.MuiFormLabel-root {
	color: white !important;
}

.MuiInputBase-root {
	color: white !important;
}

.MuiFormHelperText-root {
	color: white !important;
}
*/

const styles = theme => ({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#dd10dd',
    },
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiFormLabel-root': {
      color: 'white !important',
      fontSize: 20,
    },
    '& .MuiInputBase-root': {
      color: 'white !important',
      fontSize: 20,
    },
    '& .MuiFormHelperText-root': {
      color: 'white !important',
      fontSize: 14,
    },
  },

  btnRoot: {
    backgroundColor: '#dd10dd',
    borderRadius: 3,
    border: 0,
    color: 'white',
    fontSize: 16,
    height: 48,
    marginTop: '30px',
    padding: '0 30px',
  },

  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  input: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#fff'
    }
  },

  cssFocused: {
    borderColor: 'white !important',
  },
})

export class Contact extends Component {
  render() {
    const {classes} = this.props;

    return (
      <div>
        <div id="contact" className="gradient">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Let's chat!</h2>
                  <p>
                    Please fill out the form below to send us an email and we
                    will get back to you as soon as possible.
                  </p>
                </div>
                <form name="sentMessage" className={classes.root} id="contactForm" autoComplete="off">
                  <TextField id="name" label="Name" variant="standard" margin="normal" style={{width: '50%', paddingRight: '5px'}}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <People />
                                  </InputAdornment>
                                ),
                                shrink: 'true',
                                classes: {
                                  input:  classes.input,
                                  root: classes.cssOutlinedInput,
                                  focused: classes.cssFocused,
                                }
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: classes.cssFocused,
                                },
                              }}
                  />
                  <TextField id="email" label="Email" type="email" variant="standard" margin="normal" style={{width: '50%'}}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Email />
                                  </InputAdornment>
                                ), 
                                shrink: 'true',
                                classes: {
                                  input:  classes.input,
                                  root: classes.cssOutlinedInput,
                                  focused: classes.cssFocused,
                                }
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: classes.cssFocused,
                                },
                              }} 
                  />
                  <TextField id="message" label="Message" variant="standard" margin="normal" multiline fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MessageIcon />
                                  </InputAdornment>
                                ), 
                                shrink: 'true',
                                classes: {
                                  input:  classes.input,
                                  root: classes.cssOutlinedInput,
                                  focused: classes.cssFocused,
                                }
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.cssLabel,
                                  focused: classes.cssFocused,
                                },
                              }}
                  />
                <Button variant="contained" type="submit" fullWidth className={classes.btnRoot}><SendIcon style={{marginRight: '10px'}}/> Send</Button>
                </form>
                <div id="success" style={{marginTop: '20px'}}></div>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h2>Contact Info</h2>
                  <p style={{fontSize: 16, color: 'white'}}>
                    <i className="fa fa-map-marker" style={{paddingRight: '12px'}}></i>{this.props.data ? this.props.data.address : "loading"}
                  </p>
              </div>
              <div className="contact-item">
                  <p style={{fontSize: 16, color: 'white'}}>
                    <a href={"mailto:" + (this.props.data ? this.props.data.email : "loading")} className="link-no-styling">
                      <i className="fa fa-envelope" style={{paddingRight: '10px'}}></i>{this.props.data ? this.props.data.email : "loading"}
                    </a>
                  </p>{" "}
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                  <li>
                      <a href={this.props.data ? this.props.data.linkedin : "/"} className="social-media-buttons" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin fa-2x"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href={this.props.data ? this.props.data.facebook : "/"} className="social-media-buttons" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook fa-2x"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.twitter : "/"} className="social-media-buttons" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-twitter fa-2x"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2020 Phase Services
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Contact);

import React, { Component } from 'react';

export class about extends Component {

  render() {
    return (
        <div id="about">
        <div className="container">
          <div className="row">
            <div className="articles">
              <h2>LATEST ARTICLES</h2>
              <div className="my-carousel">

                {this.props.data ? this.props.data.map((d, i) =>

                  <div className="carousel_thing" key={i}>
                    <a href={d.url} target="_blank" rel="noopener noreferrer">
                      <div className="articleImg"> <img src={d.img} alt={d.title} /> </div>
                      <span className="articleTitle">{d.title}</span>
                      
                      <div className="articleSplit">
                        <span className="articleAuthor"><i className="fas fa-user"></i> {d.author}</span>
                        <span className="articleDate"><i className="far fa-clock"></i> {d.date}</span>
                      </div>
                      <span className="articleDescription">{d.description}</span>
                    </a>
                  </div>

                ) : 'loading'}

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default about

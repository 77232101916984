import React, { Component } from "react";

export class Header extends Component {
  render() {
    return (
      <header id="header">
        <div className="fullscreenvideo-div">
          <video loop muted autoPlay playsInline className="fullscreenvideo">
            <source src="video/header_compressed.mp4" preload="none"
type="video/mp4"/>
          </video>
        </div>
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                <img src="img/logo-text-white.png" className="header-logo" alt="logo"/>
                  <p>
                    {this.props.data ? this.props.data.paragraph : "Loading"}
                  </p>
                  <a
                    href="#features"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Learn More
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
